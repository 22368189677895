import { ButtonTR } from './modules/button';
import { CheckupTR } from './modules/checkup';
import { CommonTR } from './modules/common';
import { ErrorTR } from './modules/error';
import { FormTR } from './modules/form';
import { InformationTR } from './modules/information';
import { YupTR } from './modules/yup';

export default {
  ...ButtonTR,
  ...CheckupTR,
  ...CommonTR,
  ...ErrorTR,
  ...FormTR,
  ...InformationTR,
  ...YupTR
};
