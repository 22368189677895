export const InformationEN = {
  information: {
    'session-found-title': 'You have a session with the following information found!',
    'do-you-want-continue': 'Do you want to continue with this information?'
  }
};

export const InformationTR = {
  information: {
    'session-found-title': 'Aşağıdaki bilgilere ait bir oturumunuz bulundu!',
    'do-you-want-continue': 'Bu bilgiler ile devam etmek istiyor musunuz?'
  }
};

