export const ErrorEN = {
  error: {
    'an-error-occurred': 'An Error Occured!'
  }
};

export const ErrorTR = {
  error: {
    'an-error-occurred': 'Bir Hata Oluştu!'
  }
};

