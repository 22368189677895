/* eslint-disable no-template-curly-in-string */
export const YupEN = {
  yup: {
    required: 'This field required',
    email: 'This field must be e-mail'
  }
};

export const YupTR = {
  yup: {
    required: 'Bu alan zorunlu',
    email: 'Bu alan e-posta adresi olmalı',
    isValue: 'Bu alan zorunlu'
  }
};

// isValue: '${path} field must be ${value}'
