import { ButtonEN } from './modules/button';
import { CheckupEN } from './modules/checkup';
import { CommonEN } from './modules/common';
import { ErrorEN } from './modules/error';
import { FormEN } from './modules/form';
import { InformationEN } from './modules/information';
import { YupEN } from './modules/yup';

export default {
  ...ButtonEN,
  ...CheckupEN,
  ...CommonEN,
  ...ErrorEN,
  ...FormEN,
  ...InformationEN,
  ...YupEN
};
