import './assets/tailwind.css';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import 'ant-design-vue/dist/antd.css'; // or 'ant-design-vue/dist/antd.less'

import App from './App.vue';
import { setupI18n } from './plugins/i18n';
import router from './router';

async function bootstrap() {
  const app = createApp(App);
  const pinia = createPinia();
  await setupI18n(app);

  app.use(router);
  app.use(pinia);
  app.use(VueApexCharts);

  app.mount('#app');
}
bootstrap();
