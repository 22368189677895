export const CommonEN = {
  common: {
    start: 'Start',
    english: 'English',
    turkish: 'Turkish',
    code: 'Code'
  }
};

export const CommonTR = {
  common: {
    start: 'Başla',
    english: 'İngilizce',
    turkish: 'Türkçe',
    code: 'Kod'
  }
};
