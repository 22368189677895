export const ButtonEN = {
  button: {
    submit: 'Submit',
    next: 'Next',
    'save-and-continue': 'Save and Continue',
    previous: 'Previous',
    continue: 'Continue',
    cancel: 'Cancel',
    create: 'Create'
  }
};

export const ButtonTR = {
  button: {
    submit: 'Gönder',
    next: 'Sonraki',
    'save-and-continue': 'Kaydet ve Devam Et',
    previous: 'Önceki',
    continue: 'Devam Et',
    cancel: 'İptal',
    create: 'Oluştur'
  }
};
