export const CheckupEN = {
  checkup: {
    'saved-answers-loaded': 'Your saved answers have been loaded successfully.'
  }
};

export const CheckupTR = {
  checkup: {
    'saved-answers-loaded': 'Kaydedilmiş cevaplarınız başarıyla yüklendi.'
  }
};

