import type { App } from 'vue';
import type { I18n, I18nOptions } from 'vue-i18n';
import { createI18n } from 'vue-i18n';

import en from '@/locales/en';
import tr from '@/locales/tr';

export let i18n: ReturnType<typeof createI18n>;

async function createI18nOptions(): Promise<I18nOptions> {
  return {
    locale: 'tr', // set locale
    fallbackLocale: 'tr', // set fallback locale
    messages: { en, tr } // set locale messages
  };
}

// setup i18n instance with glob
export async function setupI18n(app: App) {
  const options = await createI18nOptions();
  i18n = createI18n(options) as I18n;
  app.use(i18n);
}
