import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import DefaultLayout from '@/layout/default.vue';
import NotFound from '@/pages/404.vue';
const Admin = () => import(/* webpackChunkName: "admin" */ '@/pages/Admin/index.vue');
const Checkup = () => import(/* webpackChunkName: "checkup" */ '@/pages/Checkup.vue');
const InformationForm = () => import(/* webpackChunkName: "information-form" */ '@/pages/InformationForm.vue');
const AcikRiza = () => import(/* webpackChunkName: "acik-riza" */ '@/pages/Agreement/AcikRiza.vue');
const KVKK = () => import(/* webpackChunkName: "kvkk" */ '@/pages/Agreement/KVKK.vue');
const Result = () => import(/* webpackChunkName: "result" */ '@/pages/Result/index.vue');
const Healthcheck = () => import(/* webpackChunkName: "healthcheck" */ '@/pages/Healthcheck.vue');
const CheckupCompleted = () => import(/* webpackChunkName: "checkup-completed" */ '@/pages/CheckupCompleted/index.vue');
const PaymentSuccess = () => import(/* webpackChunkName: "payment-success" */ '@/pages/Payment/success.vue');
const PaymentFailed = () => import(/* webpackChunkName: "payment-failed" */ '@/pages/Payment/failed.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'Information',
        component: InformationForm,
        meta: {
          title: 'Binovative Checkup'
        }
      },
      {
        path: '/acik-riza',
        name: 'AcikRiza',
        component: AcikRiza,
        meta: {
          title: 'Açık Rıza Metni'
        }
      },
      {
        path: '/kvkk',
        name: 'kvkk',
        component: KVKK,
        meta: {
          title: 'KVKK'
        }
      },
      {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: {
          title: 'Admin'
        }
      },
      {
        path: '/checkup',
        name: 'Checkup',
        component: Checkup,
        meta: {
          title: 'Checkup'
        }
      },
      {
        path: '/result',
        name: 'Result',
        component: Result,
        meta: {
          title: 'Detaylı Rapor'
        }
      },
      {
        path: '/healthcheck',
        name: 'Healthcheck',
        component: Healthcheck
      },
      {
        path: '/completed',
        name: 'CheckupCompleted',
        component: CheckupCompleted,
        meta: {
          title: 'Checkup Tamamlandı'
        }
      },
      {
        path: '/payment-success',
        name: 'PaymentSuccess',
        component: PaymentSuccess,
        meta: {
          title: 'Ödeme Başarılı'
        }
      },
      {
        path: '/payment-failed',
        name: 'PaymentFailed',
        component: PaymentFailed,
        meta: {
          title: 'Ödeme Başarısız'
        }
      },
      {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: NotFound,
        meta: {
          title: 'Sayfa Bulunamadı'
        }
      }
    ]
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
