import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "brand-icons" }
const _hoisted_2 = {
  href: "https://binovative.com",
  target: "_blank"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createElementVNode("img", {
        src: require(`@/assets/binovative-checkup-logo.png`),
        alt: "Binovative Checkup Logo",
        class: "",
        style: {"max-width":"250px","width":"100%"}
      }, null, 8, _hoisted_3)
    ])
  ]))
}