
// import { CloseOutlined } from '@ant-design/icons-vue';
import { defineComponent } from 'vue';
export default defineComponent({
  // components: {
  //   CloseOutlined
  // },
  props: {
    isWhiteLogo: {
      type: Boolean,
      default: true
    }
  },
  setup() { }
});
