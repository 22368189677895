export const FormEN = {
  form: {
    'full-name': 'Full Name',
    'company-name': 'Company Name',
    'e-mail': 'E-Mail',
    sector: 'Sector',
    language: 'Language',
    password: 'Password'
  }
};

export const FormTR = {
  form: {
    'full-name': 'Adınız Soyadınız',
    'company-name': 'Şirket Adı',
    'e-mail': 'E-Posta',
    sector: 'Sektör',
    language: 'Dil',
    password: 'Parola'
  }
};
