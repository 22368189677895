
import { defineComponent } from 'vue';

import TheBrandIcon from '@/components/TheBrandIcon.vue';
export default defineComponent({
  components: { TheBrandIcon },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = `${to.meta.title} - Binovative Checkup` || 'Binovative Checkup';
      }
    }
  }
});
