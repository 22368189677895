
import { useI18n } from 'vue-i18n';
import { setLocale } from 'yup';
import '@/assets/custom.scss';
export default {
  setup() {
    const { t } = useI18n();
    setLocale({
      mixed: {
        required: t('yup.required')
      },
      string: {
        email: t('yup.email')
      }
    });
  }
};
